var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _vm.popupParam.psiLocalVentilationId
                  ? _c(
                      "c-card",
                      {
                        staticClass: "cardClassDetailForm revisionLayout",
                        attrs: { title: "LBLREV", topClass: "topcolor-orange" },
                      },
                      [
                        _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-1" },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        required:
                                          _vm.editable && _vm.isRevision,
                                        editable:
                                          _vm.editable && _vm.isRevision,
                                        label: "LBLREVNO",
                                        name: "revisionNum",
                                      },
                                      model: {
                                        value: _vm.data.revisionNum,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "revisionNum", $$v)
                                        },
                                        expression: "data.revisionNum",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-2" },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        editable: false,
                                        label: "LBLREVPERIOD",
                                        name: "regDtStr",
                                      },
                                      model: {
                                        value: _vm.data.regDtStr,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "regDtStr", $$v)
                                        },
                                        expression: "data.regDtStr",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-1" },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        editable: false,
                                        label: "LBLREVUSER",
                                        name: "regUserName",
                                      },
                                      model: {
                                        value: _vm.data.regUserName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "regUserName", $$v)
                                        },
                                        expression: "data.regUserName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-4" },
                                  [
                                    _c("c-moc", {
                                      attrs: {
                                        editable: _vm.editable,
                                        isSubmit: _vm.saveCallData,
                                        document: _vm.data,
                                        documentId:
                                          _vm.data.psiLocalVentilationId,
                                        documentTitle: "processName",
                                        mocRelatedTaskCd: "RT00000001",
                                        label: "LBLMOCNO",
                                        name: "sopMocId",
                                      },
                                      on: {
                                        "update:document": function ($event) {
                                          _vm.data = $event
                                        },
                                      },
                                      model: {
                                        value: _vm.data.sopMocId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "sopMocId", $$v)
                                        },
                                        expression: "data.sopMocId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-4" },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        required:
                                          _vm.editable && _vm.isRevision,
                                        editable:
                                          _vm.editable && _vm.isRevision,
                                        label: "LBLREVREASON",
                                        name: "revisionContent",
                                      },
                                      model: {
                                        value: _vm.data.revisionContent,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "revisionContent",
                                            $$v
                                          )
                                        },
                                        expression: "data.revisionContent",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "LBL0010582" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-select" },
                      [
                        _vm.popupParam.psiLocalVentilationId
                          ? _c("c-select", {
                              attrs: {
                                editable: !_vm.isRevision,
                                comboItems: _vm.gridrev.data,
                                type: "custom",
                                typetext: "LBL0010565",
                                itemText: "revisionNum",
                                itemValue: "psiLocalVentilationId",
                                name: "selectedpsiLocalVentilationId",
                                label: "",
                              },
                              on: { input: _vm.rowClickRev },
                              model: {
                                value: _vm.selectedpsiLocalVentilationId,
                                callback: function ($$v) {
                                  _vm.selectedpsiLocalVentilationId = $$v
                                },
                                expression: "selectedpsiLocalVentilationId",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.editable &&
                                    _vm.popupParam.psiLocalVentilationId &&
                                    !_vm.isRevision,
                                  expression:
                                    "editable && popupParam.psiLocalVentilationId && !isRevision",
                                },
                              ],
                              attrs: { label: "LBLREV", icon: "restart_alt" },
                              on: { btnClicked: _vm.SetRevision },
                            }),
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.editable &&
                                    _vm.popupParam.psiLocalVentilationId &&
                                    _vm.isRevision,
                                  expression:
                                    "editable && popupParam.psiLocalVentilationId && isRevision",
                                },
                              ],
                              attrs: {
                                label: "LBLREVCANCEL",
                                icon: "restart_alt",
                              },
                              on: { btnClicked: _vm.CancelRevision },
                            }),
                            _vm.editable && _vm.popupParam.psiLocalVentilationId
                              ? _c("c-btn", {
                                  attrs: { label: "LBLREMOVE", icon: "remove" },
                                  on: { btnClicked: _vm.deleteData },
                                })
                              : _vm._e(),
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.data,
                                    mappingType: _vm.mappingType,
                                    label: "LBLSAVE",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveData,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "LBL0010609",
                              name: "processName",
                            },
                            model: {
                              value: _vm.data.processName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "processName", $$v)
                              },
                              expression: "data.processName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "LBL0010570",
                              name: "inOutFlag",
                            },
                            model: {
                              value: _vm.data.inOutFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "inOutFlag", $$v)
                              },
                              expression: "data.inOutFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "LBL0010571",
                              name: "sourceName",
                            },
                            model: {
                              value: _vm.data.sourceName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "sourceName", $$v)
                              },
                              expression: "data.sourceName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "LBL0010572",
                              name: "harmfulTypeCd",
                            },
                            model: {
                              value: _vm.data.harmfulTypeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "harmfulTypeCd", $$v)
                              },
                              expression: "data.harmfulTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "LBL0010573",
                              name: "hoodForm",
                            },
                            model: {
                              value: _vm.data.hoodForm,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "hoodForm", $$v)
                              },
                              expression: "data.hoodForm",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-text", {
                            attrs: {
                              suffix: "m/s",
                              editable: _vm.editable,
                              label: "LBL0010574",
                              name: "hoodVelocity",
                            },
                            model: {
                              value: _vm.data.hoodVelocity,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "hoodVelocity", $$v)
                              },
                              expression: "data.hoodVelocity",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-text", {
                            attrs: {
                              suffix: "m/s",
                              editable: _vm.editable,
                              label: "LBL0010575",
                              name: "reactionVelocity",
                            },
                            model: {
                              value: _vm.data.reactionVelocity,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "reactionVelocity", $$v)
                              },
                              expression: "data.reactionVelocity",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-text", {
                            attrs: {
                              suffix: "㎥/min",
                              editable: _vm.editable,
                              label: "LBL0010576",
                              name: "airVolume",
                            },
                            model: {
                              value: _vm.data.airVolume,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "airVolume", $$v)
                              },
                              expression: "data.airVolume",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-text", {
                            attrs: {
                              suffix: "Kw",
                              editable: _vm.editable,
                              label: "LBL0010577",
                              name: "motorVolume",
                            },
                            model: {
                              value: _vm.data.motorVolume,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "motorVolume", $$v)
                              },
                              expression: "data.motorVolume",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "LBL0010578",
                              name: "motorExplosionForm",
                            },
                            model: {
                              value: _vm.data.motorExplosionForm,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "motorExplosionForm", $$v)
                              },
                              expression: "data.motorExplosionForm",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.editable,
                              rows: 3,
                              label: "LBL0010579",
                              name: "airCleanType",
                            },
                            model: {
                              value: _vm.data.airCleanType,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "airCleanType", $$v)
                              },
                              expression: "data.airCleanType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.editable,
                              rows: 3,
                              label: "LBL0010580",
                              name: "airExhaustOrder",
                            },
                            model: {
                              value: _vm.data.airExhaustOrder,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "airExhaustOrder", $$v)
                              },
                              expression: "data.airExhaustOrder",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-process", {
                            attrs: {
                              editable: _vm.editable,
                              label: "LBL0001705",
                              multiple: "single",
                              name: "processCd",
                            },
                            model: {
                              value: _vm.data.processCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "processCd", $$v)
                              },
                              expression: "data.processCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-dept", {
                            attrs: {
                              editable: _vm.editable,
                              label: "LBL0001721",
                              name: "deptCd",
                            },
                            model: {
                              value: _vm.data.deptCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "deptCd", $$v)
                              },
                              expression: "data.deptCd",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }